import { FilterPeriodState, FilterPeriodType } from '@shared/components/filter-period';
import { GroupedOption } from '@shared/components/group-select';
import { Option } from '@shared/components/select-new/Select';
import { SwitchType } from '@shared/components/switch';
import { LocationType, WipFeesType } from '@shared/types/common/submarket';

import { FilterAutocompleteItemState } from './autocomplete';
import { Range } from './range';

export enum FilterType {
  date,
  dateRange,
  locationRadios,
  locationRegion,
  lookupAgency,
  lookupAgent,
  lookupCompany,
  lookupContact,
  lookupUser,
  multiGroupSelect,
  multiSelect,
  range,
  requirementDate,
  singleGroupSelect,
  singleSelect,
  switch,
  text,
}

export type FiltersTypeConfig<TFT = FilterType> = {
  label: string;
  labelCheckbox?: boolean;
  placeholder?: string;
  removable?: boolean;
  type: TFT;
  visible?: boolean;
};

interface DateFilterTypeConfig extends FiltersTypeConfig<FilterType.date> {
  supportedTypes?: FilterPeriodType[];
}

interface LocationRadiosFilterTypeConfig extends FiltersTypeConfig<FilterType.locationRadios> {
  defaultMetric?: Metric;
}

interface MultiGroupSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.multiGroupSelect> {
  groupOptions: GroupedOption[];
}

interface MultiSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.multiSelect> {
  options: Option[];
}

interface RangeFilterTypeConfig extends FiltersTypeConfig<FilterType.range> {
  currency?: boolean;
  currencyOptions?: Option[];
  defaultCurrency?: string;
  defaultMetric?: Metric;
  metric?: boolean;
  metricOptions?: Option[];
}

interface SingleGroupSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.singleGroupSelect> {
  groupOptions: GroupedOption[];
}

interface SingleSelectFilterTypeConfig extends FiltersTypeConfig<FilterType.singleSelect> {
  options: Option[];
}

interface SwitchFilterTypeConfig extends FiltersTypeConfig<FilterType.switch> {
  switchType: SwitchType;
  condition?: boolean;
  subLabel?: string;
}

export type FiltersTypeConfigs =
  | DateFilterTypeConfig
  | FiltersTypeConfig<FilterType.dateRange>
  | FiltersTypeConfig<FilterType.locationRegion>
  | FiltersTypeConfig<FilterType.lookupAgency>
  | FiltersTypeConfig<FilterType.lookupAgent>
  | FiltersTypeConfig<FilterType.lookupCompany>
  | FiltersTypeConfig<FilterType.lookupContact>
  | FiltersTypeConfig<FilterType.lookupUser>
  | FiltersTypeConfig<FilterType.requirementDate>
  | FiltersTypeConfig<FilterType.text>
  | LocationRadiosFilterTypeConfig
  | MultiGroupSelectFilterTypeConfig
  | MultiSelectFilterTypeConfig
  | RangeFilterTypeConfig
  | SingleGroupSelectFilterTypeConfig
  | SingleSelectFilterTypeConfig
  | SwitchFilterTypeConfig;

export enum AvailableFilters {
  advisoryStatus,
  advisoryType,
  agency,
  agent,
  amlOrKycCompleted,
  assignee,
  availableSpace,
  buildingGrade,
  buildingTypes,
  buildStatus,
  builtYear,
  capVal,
  country,
  company,
  companySource,
  companyType,
  completionDate,
  constructionStartYear,
  contact,
  contactType,
  created,
  createdCrmCompanies,
  createdCrmContacts,
  createdDateRange,
  createdTransactionsSales,
  dateAdded,
  disposalDevelopmentCompletionYear,
  disposalDevelopmentFloorSize,
  disposalDevelopmentFunding,
  disposalDevelopmentLikelihood,
  disposalDevelopmentNoOfFloors,
  disposalDevelopmentPreLetCommitted,
  disposalDevelopmentSpeculative,
  disposalDevelopmentStatus,
  disposalDevelopmentTotalAreaProposed,
  disposalDevelopmentType,
  disposalFittedSpaces,
  disposalHasPresentation,
  disposalPublishedTo,
  disposalStatus,
  disposalTermsOfEngagement,
  dueDate,
  externalOnly,
  feeBasis,
  feesStage,
  feesStatus,
  feesType,
  fitoutConcept,
  fitoutCondition,
  floorsAboveGround,
  floorsBelowGround,
  floorUnitOptions,
  has_active_disposals,
  has_active_leases,
  has_active_requirements,
  has_active_sales,
  has_active_viewings,
  has_investor_profile,
  hasAvailability,
  hasLeases,
  hasOpenTasks,
  hasSales,
  inResearchStock,
  independentBuilding,
  investmentGrade,
  investorAmountUnderManagement,
  investorNationality,
  investorRanking,
  investorRegion,
  investorType,
  invoice,
  jointExclusiveInstructions,
  kycCompleted,
  leadAgent,
  leaseBreak,
  leaseEnd,
  leaseExpiry,
  leaseReminder,
  leaseStart,
  leaseTypes,
  location,
  locationRadios,
  lotSize,
  marketing_lists,
  marketing_permission,
  nationality,
  newMatches,
  niy,
  occupiedSpace,
  office,
  ourInstructions,
  ownershipTypes,
  parkingSpacesAboveGround,
  parkingSpacesBelowGround,
  pendingMatches,
  planningStatus,
  postcode,
  probabilityRange,
  propertyAddOnFactor,
  propertySize,
  publishedToRadius,
  recordOwner,
  refurbYear,
  rentCurrency,
  rentReview,
  reverseSegmentIntersectLogic,
  riskProfile,
  salePrice,
  saleType,
  sectors,
  shareWithWip,
  showSingleFloors,
  signatureDate,
  size,
  societyDisposalLeaseTypes,
  sources,
  startDate,
  tags,
  teams,
  tenancyStatus,
  tenure,
  tny,
  transactionStatus,
  typicalFloorSize,
  updated,
  user,
  vacantSpace,
  waultToExpiry,
}

export type AvailableFiltersHash = { [key: number]: AvailableFilters };

export interface FilterLocationFieldState {
  [LocationType.postcode]?: string;
  [LocationType.submarkets]?: string[];
  [LocationType.teamSubmarkets]?: string[];
}

export interface FilterLocationRegionFieldState {
  [WipFeesType.city]?: string;
  [WipFeesType.country]?: string;
}

export interface FilterRangeState extends Range {
  metric?: Metric;
  currency?: string;
}

export interface FilterRequirementDateFieldState {
  [WipFeesType.due_date]?: Range;
  [WipFeesType.start_date]?: Range;
}

interface FilterStateValue<T = any> {
  checkboxValue?: boolean;
  value: T;
}

export type FilterStateValues = {
  [FilterType.date]: FilterStateValue<FilterPeriodState>;
  [FilterType.dateRange]: FilterStateValue<Range>;
  [FilterType.locationRadios]: FilterStateValue<FilterLocationFieldState>;
  [FilterType.locationRegion]: FilterStateValue<FilterLocationRegionFieldState>;
  [FilterType.lookupAgency]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupAgent]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupCompany]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupContact]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.lookupUser]: FilterStateValue<FilterAutocompleteItemState>;
  [FilterType.multiGroupSelect]: FilterStateValue<Id[]>;
  [FilterType.multiSelect]: FilterStateValue<Id[]>;
  [FilterType.range]: FilterStateValue<FilterRangeState>;
  [FilterType.requirementDate]: FilterStateValue<FilterRequirementDateFieldState>;
  [FilterType.singleGroupSelect]: FilterStateValue<Id | undefined>;
  [FilterType.singleSelect]: FilterStateValue<Id | undefined>;
  [FilterType.switch]: FilterStateValue<boolean | undefined>;
  [FilterType.text]: FilterStateValue<string | undefined>;
};

export type AllFilterStateValues = ValueOf<FilterStateValues>;

type PartialFilterStateValue<T extends AllFilterStateValues = AllFilterStateValues> = FilterStateValue<
  Partial<T['value']>
>;

export type PartialFilterStateValues = {
  [FilterType.date]: PartialFilterStateValue<FilterStateValues[FilterType.date]>;
  [FilterType.dateRange]: PartialFilterStateValue<FilterStateValues[FilterType.dateRange]>;
  [FilterType.locationRadios]: PartialFilterStateValue<FilterStateValues[FilterType.locationRadios]>;
  [FilterType.locationRegion]: PartialFilterStateValue<FilterStateValues[FilterType.locationRegion]>;
  [FilterType.lookupAgency]: PartialFilterStateValue<FilterStateValues[FilterType.lookupCompany]>;
  [FilterType.lookupAgent]: PartialFilterStateValue<FilterStateValues[FilterType.lookupAgent]>;
  [FilterType.lookupAgent]: PartialFilterStateValue<FilterStateValues[FilterType.lookupCompany]>;
  [FilterType.lookupCompany]: PartialFilterStateValue<FilterStateValues[FilterType.lookupCompany]>;
  [FilterType.lookupContact]: PartialFilterStateValue<FilterStateValues[FilterType.lookupContact]>;
  [FilterType.lookupUser]: PartialFilterStateValue<FilterStateValues[FilterType.lookupUser]>;
  [FilterType.multiGroupSelect]: FilterStateValue<FilterStateValues[FilterType.multiGroupSelect]['value']>;
  [FilterType.multiSelect]: FilterStateValue<FilterStateValues[FilterType.multiSelect]['value']>;
  [FilterType.range]: PartialFilterStateValue<FilterStateValues[FilterType.range]>;
  [FilterType.requirementDate]: PartialFilterStateValue<FilterStateValues[FilterType.requirementDate]>;
  [FilterType.singleGroupSelect]: FilterStateValue<FilterStateValues[FilterType.singleGroupSelect]['value']>;
  [FilterType.singleSelect]: PartialFilterStateValue<FilterStateValues[FilterType.singleSelect]>;
  [FilterType.switch]: PartialFilterStateValue<FilterStateValues[FilterType.switch]>;
  [FilterType.text]: PartialFilterStateValue<FilterStateValues[FilterType.text]>;
};
