import { t } from '@core/i18n';

export const normalizedHTMLText = (text?: string) => {
  if (!text) return '';

  return text.replace(/\n/g, '<br />');
};

export const normalizedHTMLTextWithSpaces = (text?: string) => {
  if (!text) return '';

  // Replace each space with an HTML entity &nbsp; also a line break replace on the HTML <br /> tag
  return text.replace(/ /g, '&nbsp;').replace(/\n/g, '<br />');
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getMetricString = (metric: Metric) => {
  // TODO: Refactor so that we supply config as an argument vs. referencing window.AS
  // TODO: We will need to make sure that we have separated utils, e.g. one for rent / size / etc.

  switch (metric) {
    case 'sqft':
      return t('sq_ft');

    case 'sqm':
    case 'sqm_month':
      return t(window?.AS?.constants?.localisation?.use_sqm_symbol ? 'sq_m_1' : 'sq_m');

    case 'acre':
    case 'acres':
      return t('acres');

    case 'desk':
    case 'desks':
      return t('desks');

    case 'hectare':
    case 'hectares':
      return t('hectares');

    case 'annum':
      return t('annum');

    case 'month':
      return t('month');

    case 'bed':
    case 'beds':
      return t('beds');

    default:
      return metric ?? '';
  }
};

export const formatValueMetric = (value: string | number, metric: Metric) => {
  if (!value) {
    return '';
  }

  return value.toString() + ' / ' + getMetricString(metric);
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const getAddressHtml = (line1 = '', line2 = '', town = '', county = '', postcode = '', country = '') => {
  const addressString = [line1, line2, town, county, postcode, country].filter((value) => value).join(',\n');

  return addressString ? normalizedHTMLText(addressString) : '-';
};

export const transformTextToSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '');
};
